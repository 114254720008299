import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Features from "../components/Features";
import FullWidthImage from "../components/FullWidthImage";
// import FullWidthImage from "../components/FullWidthImage2";
import { Box, Button, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import GoogleMapReact from 'google-map-react';
import Gallery from '@browniebroke/gatsby-image-gallery'
import Debug from "../components/Debug";
import useSiteMetadata from "../components/SiteMetadata";
import "animate.css/animate.compat.css"
import "../style/index.css"
import FlowersTopLeft from "../../static/svg/flowersTopLeft.svg"
import FlowersMiddle from "../../static/svg/flowersMiddle.svg"




// eslint-disable-next-line


import ScrollAnimation from 'react-animate-on-scroll';
import AccordionComp from "../components/Accordion";
import StickyAppBar from "../components/StickyAppBar";
import CallToAction from "../components/CallToAction";
import { useCookies } from "react-cookie";

const howToBegin = [
  {
    label: "Niezbędne dokumenty",
    subtext: "Wszystkie najważniejsze informacje"

  },
  {
    label: "Przewodnik dla rodzin pensjonariuszy",
    subtext: "Dokładny opis jak działa nasz dom"
  },
]


const texts = {
  nature: {
    label: "Spokojna okolica",
    content: "Nasz Dom Opieki znajduje się z dala od zgiełku dużych miast. Lokalizacja naszego Domu gwarantuje spokój i wytchnienie."
  },
  localisation: {
    label: "Wygodna lokalizacja",
    content: "Świdnica to mała, spokojna miejscowość położona 7 km od Zielonej Góry. Posiada świetne połączenia drogowe z okolicznymi miastami oraz sąsiednimi województwami. Do Państwa dyspozycji oddajemy wydzielone miejsca parkingowe."
  },
  disabled: {
    label: "Dom Opieki przyjazny osobom niepełnosprawnym",
    content: "Nasz Dom Opieki nie posiada barier architektonicznych i jest dostosowany do osób poruszających się na wózku inwalidzkim. Wszystkie łazienki i pokoje posiadają udogodnienia dla osób niepełnosprawnych oraz o zmniejszonej sprawności ruchowej."
  },
  complex: {
    label: "Kompleksowa opieka",
    content: "Oferujemy kompleksowe usługi opiekuńcze dostosowane do indywidualnych potrzeb. Udzielamy pomocy w ubieraniu się, jedzeniu, myciu, kąpaniu oraz w przyjmowaniu leków. Regularnie odwiedza nas fryzjer i kosmetyczka. Ponadto nasz Dom Opieki objęty jest opieką duszpasterską."
  },
  accomodations: {
    label: "Liczne udogodnienia dla mieszkańców",
    content: "Oferujemy komfortowe, estetycznie umeblowane pokoje z oknami z widokiem na las. W pokojach znajdują się elektryczne łóżka rehabilitacyjne. Do dyspozycji naszych Pensjonariuszy znajduje się telewizor, internet, telefon, planszówki oraz bogato zaopatrzona biblioteczka."
  },
  freetime: {
    label: "Atrakcyjne spędzanie wolnego czasu",
    content: "W naszym Domu Seniora dbamy o aktywność i integrację! Nasi Pensjonariusze biorą udział m.in. w grach towarzyskich, wieczorkach muzyczno-tanecznych, spotkaniach z ludźmi ze świata kultury oraz w seansach filmowych. Prowadzimy także zajęcia malarskie oraz warsztaty robótek ręcznych.",
    double: true,
  },
  medical: {
    label: "Opieka lekarska i pielęgniarska",
    content: "W naszym Domu Opieki zatrudniony jest lekarz, który konsultuje wszelkie aspekty dotyczące zdrowia naszych Seniorów. Ponadto codziennie wykonujemy pomiary m.in. ciśnienia tętniczego, natlenowania krwi oraz temperatury."
  },
  experience: {
    label: "Doświadczony personel",
    content: "Posiadamy już kilkanaście lat w opiece nad osobami starszymi i niepełnosprawnymi. Nasza kadra zdobywała doświadczenie w renomowanych polskich ośrodkach oraz domach spokojnej starości, przez co gwarantuje kompleksową opiekę na najwyższym poziomie."
  },
  rehab: {
    label: "Rehabilitacja",
    content: "Prowadzimy rehabilitację indywidualną oraz grupowe zajęcia terapeutyczne. Naszym celem jest, aby nasi Pensjonariusze jak najdłużej mogli cieszyć się dobrym zdrowiem i sprawnością ruchową. Wspieramy osoby niepełnosprawne w odzyskiwaniu sprawności, jak samodzielne jedzenie czy ubieranie się."
  },
  atmosphere: {
    label: "Rodzinna, przyjazna atmosfera",
    content: "W naszym Domu panuje pogodna atmosfera pełna ciepła i wsparcia. Ponadto jesteśmy bardzo otwarci na odwiedziny rodzin naszych Seniorów."
  },
  cuisine: {
    label: "Smaczna, zdrowa kuchnia",
    content: "Oferujemy pełne wyżywienie w postaci 5 pełnowartościowych posiłków dziennie. Wybieramy produkty dopasowane do potrzeb osób starszych i niepełnosprawnych. Dbamy o odpowiednie nawodnienie oraz gwarantujemy nieograniczony dostęp do napojów. Realizujemy także diety indywidualne, zgodnie z zaleceniami lekarza."
  },
  priest: {
    label: "Opieka duszpasterska",
    content: "Pensjonariusze naszego Domu mają możliwość dobrowolnego udziału w mszy świętej odprawionej na terenie Domu oraz do przyjęcia posługi duszpasterskiej. Dla chętnych codziennie odmawiana jest wspólna koronka ku miłosierdziu."
  },
  security: {
    label: "Bezpieczeństwo",
    content: "Gwarantujemy całodobową opiekę przez 7 dni w tygodniu, także w święta. Nasz Dom Opieki znajduje się na ogrodzonym, stale monitorowanym terenie. Aby zagwarantować najwyższe możliwe bezpieczeństwo współpracujemy z prywatną firmą ochroniarską. "
  },
  activisation: {
    label: "Rehabilitacja i aktywizacja",
    content: "Prowadzimy rehabilitację indywidualną oraz grupowe zajęcia terapeutyczne. Prowadzimy terapię zajęciową, muzykoterapię oraz zajęcia ekspresji ruchowej. Prowadzimy aktywizację społeczną poprzez organizowanie spotkań z zewnętrznymi gośćmi, w tym m.in. z dziećmi z lokalnych szkół. ",
    double: true,
  },
  special: {
    type: "special"
  },
};

const firstText = "Oferujemy całodobową opiekę nad seniorami oraz osobami niepełnosprawnymi. Każdy z naszych Pensjonariuszy otoczony jest kompleksową opieką, w tym także lekarską. Ustronna lokalizacja naszego Domu zapewnia spokój i bezpieczeństwo. Zapraszamy Państwa do kontaktu z nami - odpowiemy na wszelkie Państwa pytania."




const textsIds1 = [
  "complex",
  "disabled",
  "experience",
]

const textsIds2 = [
  "localisation",
  "nature",
  "accomodations",
]

const textsIds3 = [
  "activisation",
  "medical",
  "security",
]

const textsIds4 = [
  "atmosphere",
  "freetime",
  "cuisine",
]


const faq = [
  {
    label: "Jakie rodzaje pokoi są dostępne?",
    content: "Oferujemy pokoje dwu- i trzyosobowe, wszystkie z oknami z widokiem na las. Nasz Dom Opieki nie posiada barier architektonicznych i jest całkowicie przystosowany do potrzeb Seniorów oraz osób niepełnosprawnych."
  },
  {
    label: "Ilu Pensjonariuszy przebywa w Waszym domu?",
    content: "Nasz Dom Opieki jest domem kameralnym. Przyjmujemy ściśle określoną liczbę pensjonariuszy - tak, abyśmy mieli obfitość czasu dla każdego z nich."
  },
  {
    label: "Jaki rodzaj opieki medycznej oferujecie?",
    content: "W naszym Domu Opieki zatrudniony jest lekarz medycyny. Prowadzi on regularne, okresowe badanie naszych Pensjonariuszy. Ponadto konsultujemy z nim wszelkie zmiany w stanie zdrowia naszych Pensjonariuszy. Codziennie wykonujemy pomiary ciśnienia tętniczego, natlenowania krwi oraz temperatury. W przypadku pacjentów z cukrzycą wykonujemy codzienne pomiary glukozy we krwi."
  },
  {
    label: "Jaką rehabilitację prowadzicie?",
    content: "Prowadzimy szeroko zakrojoną rehabilitację indywidualną, w tym masaż fizjoterapeutyczny oraz codzienne grupowe zajęcia rehabilitacyjne. Jednym z naszych celów jest usprawnianie naszych Pensjonariuszy, aby jak najdłużej pozostali aktywni na miarę swoich możliwości."
  },

  {
    label: "Jakie zajęcia dla Seniorów prowadzicie?",
    content: "Prowadzimy zajęcia manualne, artystyczne oraz ruchowe. Ponadto regularnie odwiedzają nas zewnętrzni goście, między innymi muzycy, aktorzy, a także dzieci z okolicznych szkół."
  },

  {
    label: "Jak wspieracie Seniorów w codziennych czynnościach?",
    content: "Jeśli Pensjonariusz tego potrzebuje, oferujemy wsparcie w zakresie toalety, higieny osobistej i ubioru. Ponadto asystujemy naszym Pensjonariuszom w prawidłowym przyjmowaniu zaleconych leków."
  },
  {
    label: "Czy rodzina może odwiedzać swojego Seniora?",
    content: "Jak najbardziej! Prowadzimy Dom otwarty dla rodzin i staramy się, aby w trakcie odwiedzin czuli się Państwo mile widziani w naszym Domu. Godziny odwiedzin to 14:00-17:00 każdego dnia, także w weekendy. Możliwe są odwiedziny także w innych godzinach, które ustalą Państwo z nami indywidualnie."
  },
  {
    label: "Czy przyjmujecie Seniorów z chorobami medycznymi oraz demencją?",
    content: "Jak najbardziej. Nasz Dom prowadzi opiekę nad osobami starszymi oraz osobami niepełnosprawnymi. Jesteśmy przygotowani do sprawowania kompleksowej opieki nad osobami obciążonymi chorobami, z wyjątkiem sytuacji opisanych w następnej odpowiedzi."
  },
  {
    label: "Czy są istnieją ograniczenia przyjęć w związku z chorobami i stanem medycznym?",
    content: "W zdecydowanej większości nie ma ograniczeń, abyśmy przyjęli Seniora do naszego Domu. Istnieją jednak pewne wyjątki. Nie nawiązujemy współpracy w przypadku chorób wymagających hospitalizacji bądź pobytu w ośrodku leczniczym oraz w przypadku częstych agresywnych zachowań Seniora."
  },
  {
    label: "Czy w przypadku pogorszenia stanu już przyjętego Pensjonariusza rozwiązujecie umowę?",
    content: "Naszą misją jest towarzyszyć naszym Pensjonariuszom tak długo, jak jest to możliwe. W przypadku pogorszenia się stanu naszego Pensjonariusza jesteśmy gotowi na dalszą opiekę. Jedynym wyjątkiem mogą być sytuacje, kiedy dany Pensjonariusz wymaga opieki szpitalnej lub jest wysoce agresywny."
  }
]









const flowerStyle = {
  zIndex: 0,
  position: "absolute",
  "& svg": {
    width: {
      xs: "100px",
      sm: "200px"
    },
    height: {
      xs: "100px",
      sm: "200px"
    },
  }
};

const TextIds = ({ textsIds, image, reverse = false, className, prepend }) => {




  return (
    <Box sx={{
      mb: 2,

      // "&:hover": {
      //   opacity: 0.6,
      //   cursor: "pointer",
      // }
    }}
    //  onClick={onClick}
    >

      <Grid container spacing={0.5}

        direction={reverse ? "row-reverse" : "row"}
      // sx={{
      //   flexDirection: 
      // }}
      >

        <Grid xs={12} sm={4} item sx={{
          alignSelf: "flex-end",

        }}>


          <Stack sx={{
            alignItems: "center"
          }}>
            {/* <Debug data={{ image }} /> */}
            <GatsbyImage
              className={className}
              alt="zdjęcie"
              image={image}
            // transformOptions={{fit: "cover", cropFocus: "attention"}}

            />
          </Stack>
        </Grid>



        <Grid xs={12} sm={8} item sx={{ alignSelf: "flex-end" }}>

          <Stack spacing={0.5}>

            {
              prepend && prepend
            }
            {

              textsIds.map(textId => {

                return (
                  <Box key={textId}
                    sx={{
                      // p: 1,
                      // boxShadow: "none"
                    }}>



                    {/* <ScrollAnimation animateIn="fadeIn" animateOnce={true}> */}
                    <Paper
                      elevation={0}
                      sx={{
                        p: 1,
                        px: "10px",
                        borderRadius: 2,
                        zIndex: 3,
                        position: "relative",

                        bgcolor: theme => theme.palette.paper.dark,
                      }}>



                      <Stack sx={{

                      }}>

                        <Typography variant="h5" textAlign={"left"}>{texts[textId].label}</Typography>

                        <Typography variant="body1" textAlign={"left"}>{texts[textId].content}</Typography>
                      </Stack>

                    </Paper>
                    {/* </ScrollAnimation> */}

                  </Box>
                )


              }
              )
            }
          </Stack>
        </Grid>





      </Grid>


    </Box>


  )
}



















export const IndexPage = ({
  data
}) => {

  const {
    pageData: {
      frontmatter: {
        image,
        title,
        // subheading,
      }
    },

  } = data;

  // const matches = useMediaQuery('(min-width:900px)');


  const { tel,
    linkgooglemap,
    address } = useSiteMetadata();

  const images = data.images.edges.map(({ node }) => node.childImageSharp)


  const [cookies, setCookie] = useCookies(["consentToPolicies"]);




  const [defaultExpanded, setDefaultExpanded] = React.useState(false)
  const [expandedIndex, setExpandedIndex] = React.useState(undefined)


  const assetsimages = React.useMemo(() => {

    let result = {}

    data.assetsimages.edges.forEach(({ node }) => {

      // console.log(node)

      // node.childImageSharp

      result[node.name] = node.childImageSharp.gatsbyImageData

    })


    return result
  }, [])


  const handleAccordionChange = (index) => {
    setExpandedIndex(index)
  };


  const heroImage = getImage(image) || image;





  // return <h1>zonk 2</h1>

  return (
    <Layout>

      <div >

        <Box
          sx={{
            // bgcolor: "#f1f1f3"
            // zIndex: 1,
            // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            // boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
            zIndex: 3,
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
          }}>

          <FullWidthImage 
          img={heroImage}
            title={title}
            // subheading={subheading}
            imgPosition="top right"
            objectFit="contain"
          />



        </Box>



        {/* <Button onClick={() => {

          setCookie("consentToPolicies", undefined, {
            path: "/",
            maxAge: 31536000
          });
        }
        }
        >wycofaj ciasteczko</Button> */}



        <Box sx={{
          position: "relative"

        }}>

          <Box sx={{

            top: "0px",
            left: 0,
            ...flowerStyle
          }}>
            <FlowersTopLeft

            // width={"100px"}


            />
          </Box>

          <Box sx={{
            
            top: "0px",
            right: 0,
            transform: "rotate(90deg)",
            ...flowerStyle
          }}>
            <FlowersTopLeft />
          </Box>

          <Box sx={{
            ...flowerStyle,
            bottom: "0px",
            transform: "rotate(270deg)",
            left: 0,
       
          }}>
            <FlowersTopLeft />
          </Box>

          <Box sx={{
            ...flowerStyle,
            bottom: "0px",
            right: 0,
            transform: "rotate(180deg)",
       
          }}>
            <FlowersTopLeft />
          </Box>



          <Box sx={{
            position: "absolute",
            top: "40%",
            left: "-30px",
            // right: 0,
            // transform: "rotate(180deg)",
            zIndex: 0,
          }}>
            <FlowersMiddle />
          </Box>

          <Box sx={{
            position: "absolute",
        
            top: "40%",
            right: "-30px",
            // right: 0,
            transform: "rotate(180deg)",
            // ...flowerStyle,

          }}>
            <FlowersMiddle />
          </Box>

          <Container maxWidth="sm" sx={{
            // my: 6,
            marginTop: "-10px",
            zIndex: 5,
            position: "relative"
          }}>



            <CallToAction
              direction="row"
            />


          </Container>





          <Container maxWidth="md" sx={{
            my: {
              sm: 8,
              xs:3
            }
          }}>


            {/* <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
            </ScrollAnimation> */}

            <Typography variant="body2" textAlign={"center"}>{firstText}</Typography>




          </Container>







          <Container maxWidth="md">


            <Box id={"oferta"} sx={{ my: 4 }}>
              <TextIds textsIds={textsIds1} image={assetsimages["one"]} className={"clipLeafLeft"} prepend={
                <Typography variant="h2" sx={{ pb: 1, textAlign: {
                  xs: "center",
                  sm: "left"
                } }} >Oferujemy</Typography>

              } />
              <TextIds textsIds={textsIds2} image={assetsimages["nurse"]} reverse={true} className={"clipLeafRight"} />
              <TextIds textsIds={textsIds3} image={assetsimages["three"]} className={"clipLeafLeft"} />
              <TextIds textsIds={textsIds4} image={assetsimages["park1"]} reverse={true} className={"clipLeafRight"} />




            </Box>
          </Container>

          {/* <Container maxWidth="md"> */}


          {/* <Box id={"galeria"} sx={{ mt: 4 }}>
            <Stack alignItems={"center"}>

              <Typography variant="h2" textAlign={"center"}>Galeria</Typography>
              <Typography variant="caption" textAlign={"center"}>Kliknij na zdjęcie, aby je powiększyć.</Typography>
            </Stack>

          </Box> */}
        </Box>


   

{/* 
        <Box sx={{
          cursor: "pointer",

        }}>

          <Gallery
            colWidth={20}
            mdColWidth={10}
            gutter="1px"
            images={images}
          // customWrapper={CustomWrapper}
          />
        </Box> */}



        <Box  sx={{ mt: 4,
        p: 4,
          bgcolor: theme => theme.palette.green.main,
          color: "white"
         }}>
            <Stack alignItems={"center"}>
              <Typography variant="body1" textAlign={"center"}>Naszą misją jest szybko rozpoznawać i reagować na potrzeby naszych Pensjonariuszy.</Typography>
            </Stack>

          </Box>

        {/* </Container> */}



        {/* <Container maxWidth="lg">
          <Box id={"galeria"} sx={{ my: 4 }}>
            <Typography variant="h2" textAlign={"center"}>Polecają nas</Typography>
          </Box>
        </Container> */}


        {/* <Typography id={"dojazd"} variant="h2" textAlign={"center"}>Dojazd</Typography> */}


        <Box sx={{
          position: "relative",
          // border: "1px solid black",
        }}>

          <Box sx={{
            top: "0px",
            left: 0,
            ...flowerStyle
          }}>
            <FlowersTopLeft />
          </Box>

          <Box sx={{
            top: "0px",
            right: 0,
            transform: "rotate(90deg)",
            ...flowerStyle
          }}>
            <FlowersTopLeft />
          </Box>


          <Container maxWidth="md" sx={{ position: "relative" }}>




            <Box id={"faq"} sx={{
              py: 10,
            }} >



              <Grid container spacing={2}>

                <Grid item xs={12} sm={4} sx={{
                  alignSelf: "flex-start",
                  // justifyContent: "flex-end"
                }}>
                  <Stack alignItems={"center"}>

                    <GatsbyImage

                      className={"clipLeafLeft"}
                      alt="zdjęcie"
                      image={assetsimages["park2"]}

                    />
                  </Stack>

                </Grid>

                <Grid item xs={12} sm={8}>


                  <Typography variant="h2" textAlign={"left"}>Najczęściej zadawane pytania</Typography>


                  <Stack sx={{ gap: 1 }} alignItems={"flex-start"}>


                    {/* <Button onClick={() => setDefaultExpanded(state => !state)}>{defaultExpanded ? "zwiń wszystko" : "rozwiń wszystko"}</Button> */}

                    {/* {expandedIndex} */}

                    <Typography variant="caption" textAlign={"center"}>Kliknij na pytanie, aby wysunąć odpowiedź.</Typography>

                    {
                      faq.map(({ label, content }, i) => {
                        return (<AccordionComp
                          key={i.toString()}
                          defaultExpanded={false}
                          expanded={expandedIndex === i}
                          label={label}
                          onChange={() => handleAccordionChange(i)}
                          children={<Typography variant="caption" textAlign={"left"}>{content}</Typography>}
                        />)




                      })
                    }

                  </Stack>
                </Grid>

              </Grid>

              {/* <Grid container spacing={2}>
              {
                howToBegin.map(({ label, subtext }, i) => {
                  return (<Grid xs={12} sm={6} item key={i.toString()}>
                    <Paper>
                      <Stack>
                        <Typography variant="h5" textAlign={"left"}>{label}</Typography>
                        <Typography variant="caption" textAlign={"left"}>{subtext}</Typography>
                      </Stack>
                    </Paper>
                  </Grid>)
                })
              }
            </Grid> */}

            </Box>
          </Container>

        </Box>


        {/* <Box id={"become"} sx={{ bgcolor: theme => theme.palette.paper.dark }}>

          <Container maxWidth="md">

            <Typography variant="h5" textAlign={"left"}>Zostań naszym mieszkańcem</Typography>

          </Container>

        </Box> */}






        {/* <div id={"dojazd"} style={{ height: '300px', width: '100%' }}>




          <GoogleMapReact
            bootstrapURLKeys={{ key: "" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          />
        </div> */}

      </div>
    </Layout>

  );
};




export default IndexPage;

export const Head = () => {

  const { tel,
    linkgooglemap,
    address,
    title,
    description,
    siteUrl
  } = useSiteMetadata();

  return (<>
    {/* https://www.searchenginejournal.com/important-tags-seo/156440/ */}
    <title>{title}</title>
    <meta name="description" content={description} />
    {/* <meta name="robots" content="nofollow"> */}

    <link rel="canonical" href={siteUrl} />

    <meta property="og:title" content={title} />

    <meta property="og:description" content={description} />

    <meta property="og:image" content={`${siteUrl}/img/map.jpg`} />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    {/* <meta charset="UTF-8" /> */}




  </>


  )
}


export const pageQuery = graphql`
  query IndexPageQuery {
   pageData: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              layout: FULL_WIDTH
          placeholder: BLURRED

              )

          }
        }
        heading
        subheading

      }
    }

    
assetsimages: allFile(
  filter: {sourceInstanceName: {eq: "assetsimages"}}
  # sort: {order: DESC, fields: name}
  ) {
  edges {
    node {
      name
      childImageSharp {
        gatsbyImageData(
          width: 270
          height: 380
          placeholder: BLURRED
        )
      }
    }
  }
}
images: allFile(
  filter: {sourceInstanceName: {eq: "gallery"}}
  # sort: {order: DESC, fields: name}
  ) {
  edges {
    node {
      childImageSharp {
        thumb: gatsbyImageData(
          width: 660
          height: 660
          # aspec
          placeholder: BLURRED
        )
        full: gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
}


  }
`;



