import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import logo from "../img/logo.svg";
import { Box, Stack } from "@mui/material";
// import { ReactComponent as ReactLogo } from "../img/logo.svg";

export default function FullWidthImage(props) {
  const {
    height = 200,
    img,
    title,
    // subheading,
    // imgPosition = "top left",
    // objectFit = "cover"
  } = props;

  return (
    <>

        <Box sx={{
          position: "relative",
          // border: "1px solid red",
          // width: "f"
          zIndex: 3,

        }}>







          <GatsbyImage
            // className="clipMask"

            image={img}
            // objectFit={objectFit}
            // objectPosition={imgPosition}
            style={{
              // gridArea: "1/1",
              // You can set a maximum height for the image, if you wish.
              // maxHeight: height,
              // width: "500px",
              // width: "100%",
              height: "350px",
            zIndex: 3,

            }}
            layout="fullWidth"
            // You can optionally force an aspect ratio for the generated image
            // aspectratio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt="Zdjęcie główne Domu Opieki"
            formats={["auto", "webp", "avif"]}
          />

          {/* <Box sx={{
            position: "absolute",
            background: "rgb(123,71,105)",
            background: "linear-gradient(267deg, rgba(123,71,105,0) 0%, rgba(123,71,105,1) 100%)",
            width: "100px",
            height: "100%",
            // right: 0,
            right: "200px",

            top: 0,
            zIndex: 3
          }}>
          </Box> */}
        </Box>


    </>
  );
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  subheading: PropTypes.string,
};
