import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';



const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
), {
    shouldForwardProp: (prop) => prop !== "expanded" && prop !== "isLastRow" && prop !== "hideArrow",
})(({ theme, expanded, isLastRow, disabled }) => {

    // console.log(expanded)

    

    return ({
        justifyContent: "flex-start",
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg) scale(0.6)',
            display: disabled && "none"
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            transform: 'scale(0.6)',
            display: disabled && "none"

        },
        '& .MuiAccordionSummary-content': {
            marginRight: theme.spacing(1),
            marginTop: "0px",
            marginBottom: "0px",
            flexGrow: "unset",
        },
        paddingLeft: "0px",
        minHeight: "16px",
        // backgroundColor: theme.palette.mode === "dark" ? `${theme.palette.table.dark} !important` : `${theme.palette.background.main} !important`,

        display: disabled && "none",

        // borderTop: `1px solid ${theme.palette.table.main}`,
        // borderLeft: `1px solid ${theme.palette.table.main}`,
        // borderRight: `1px solid ${theme.palette.table.main}`,
      
    })
});

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0),
    paddingTop: "0px",
    paddingBottom: "4px",
    // borderTop: 'none',
    // border: "none",

}));



 
export const AccordionComp = (props) => {



    const {

        label,
        children,
        expanded: propsExpanded = false,
        setExpanded: propsSetExpanded,
        defaultExpanded,
        unmountOnExit = true,
        isLastRow,
        disabled,
        ...rest
    } = props

    // useTraceUpdate(props)


    const [expanded, setExpanded] = React.useState(disabled) // musi być, inaczej alert



    // console.log(defaultExpanded)

    React.useEffect(() => {

        if (propsExpanded !== expanded) {
            setExpanded(propsExpanded)
        }
    }, [propsExpanded])




    function handleDetailsClick(e) {

        e.stopPropagation()
    }

    function handleSummaryClick(e) {


        if (defaultExpanded || disabled) {
            e.stopPropagation()
            e.preventDefault()
            return
        }
        setExpanded(state => !state)
        propsSetExpanded && propsSetExpanded(state => !state)
    }



    // if (disabled) {
    //     return children
    // }
    return (<>

        <Accordion
            {...rest}

            TransitionProps={{ unmountOnExit: unmountOnExit }}

            sx={{
                border: "none",
                background: "none",
                bgcolor: theme => theme.palette.paper.dark,
                width: "100%",
                px: "8px",
                borderRadius: 2,
                // py: "2px",

                "&.Mui-disabled": {
                    background: "none"
                }
                // borderBottom: theme => `1px solid ${theme.palette.table.main}`,
            }}
            // defaultExpanded={defaultExpanded}
            defaultExpanded={defaultExpanded}
            expanded={expanded}            
        >
            {
                //    !defaultExpanded && 

                <AccordionSummary
                    isLastRow={isLastRow}

                    onClick={handleSummaryClick}
                    disabled={disabled}
                    expanded={expanded}
                    sx={{
                        "&.Mui-disabled": {
                            opacity: 1
                        }
                    }}
                >
                    <Box

                    sx={{
                        py: "2px",

                        // "&": (theme) => ({
                        //   ...mixinPhysSingleOptionWrapper({ theme }),
                        // }),
                      }}
                    >

                        <Typography
                        //  variant="caption" 
                         variant="body1" 
                         
                         sx={{
                            // fontSize: "0.7rem",
                            // fontWeight: 900,


                        }}>{label}</Typography>
                    </Box>

                </AccordionSummary>}






            <AccordionDetails onClick={handleDetailsClick}


            >

<Typography
                     
                         variant="body1" 
                         
                         sx={{
                        


                        }}>{children}</Typography>
                
            </AccordionDetails>
        </Accordion>



    </>)


};

export default AccordionComp


// export default function CustomizedAccordions({ blueprintId, role }) {



//     const [expandedIds, setExpandedIds] = React.useState([]);



//     function handleClick(id) {

//         if (expandedIds.indexOf(id) === -1) {

//             setExpandedIds(state => ([...state, id]))

//         } else {
//             const newIds = expandedIds.filter(locId => locId !== id)
//             setExpandedIds(newIds)
//         }


//     }
//     const {
//         blueprintObject = {}
//     } = useBlueprints({
//         blueprintId
//     })


//     const { materials = [], recomMaterials = [] } = blueprintObject


//     const items = React.useMemo(() => {
//         if (role === "recom") {
//             return recomMaterials

//         } else {
//             return materials
//         }
//     })


//     return (
//         <div>

//             {/* <Debug data={{blueprintObject}} /> */}

//             {
//                 items.map(({ label, id }, i) => {


//                     return <Box
//                         key={i.toString()}
//                         onClick={() => handleClick(id)}
//                     >

//                         <SingleMaterial label={label} expandedIds={expandedIds} blueprintId={id} />

//                     </Box>
//                 })
//             }


//         </div>
//     );
// }
